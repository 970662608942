import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { IUserItem } from 'src/types/user'
import { EditUserSchema, NewUserSchema } from 'src/validators/user.schemas'
import { fData } from 'src/utils/format-number'
import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify'
import { RHFSelect } from 'src/components/hook-form/rhf-select'
import RHFSwitch from 'src/components/hook-form/rhf-switch'
import { useUserStore } from 'src/store/user'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: IUserItem
}

export default function UserCreateEditForm({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUserStore()

  const usersPermissons = useMemo(() => {
    if (user?.role === 'admin') {
      return [
        { label: 'Usuário/Vendedor', value: 'user' },
        { label: 'Franqueado', value: 'franchisee' },
        { label: 'Administrador', value: 'admin' },
      ]
    }
    return [{ label: 'Usuário/Vendedor', value: 'user' }]
  }, [user])

  const password = useBoolean()
  const passwordConfirm = useBoolean()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
      name: currentUser?.name || '',
      active: currentUser?.active,
      email: currentUser?.email || '',
      comission: currentUser?.comission || 0,
      document: currentUser?.document || '',
      role: currentUser?.role || 'user',
      photo: currentUser?.photo || null,
      password: '',
      password_confirmation: '',
    }),
    [currentUser],
  )

  const methods = useForm<IUserItem>({
    resolver: yupResolver(currentUser ? EditUserSchema : NewUserSchema),
    defaultValues,
  })

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IUserItem) => {
      try {
        let userId = currentUser?._id

        if (currentUser) {
          await axiosInstance.put(`/user/${currentUser._id}`, {
            ...data,
            photo: undefined,
            email: undefined,
            password: undefined,
          })
        } else {
          const { data: response } = await axiosInstance.post('/user', data)

          userId = response._id
        }

        if (typeof data.photo !== 'string' && data.photo) {
          const formDataUpload: FormData = new FormData()
          formDataUpload.append('photo', data.photo)
          try {
            await axiosInstance.post(`/user/${userId}/photo`, formDataUpload)
            enqueueSnackbar('Foto de perfil atualizada com sucesso !')
          } catch (error) {
            enqueueSnackbar('Erro ao enviar a foto perfil !', {
              variant: 'error',
            })
          }
        }

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Usuarios criada com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      if (file) {
        setValue('photo', newFile, { shouldValidate: true })
      }
    },
    [setValue],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>{currentUser ? 'Atualizar Usuarios' : 'Cadastrar Usuarios'}</DialogTitle>

        <DialogContent>
          <Box rowGap={2} columnGap={2} padding={1} display='grid'>
            <RHFUploadAvatar
              name='photo'
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant='caption'
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Permitido *.jpeg, *.jpg, *.png, *.gif
                  <br /> tamanho máximo de {fData(3145728)}
                </Typography>
              }
            />

            <RHFTextField name='name' label='Nome' />
            <RHFTextField name='email' label='Email' />
            {/*     <RHFTextField name='comission' label='Comissão (%)' /> */}
            <RHFTextField name='document' mask='cpf' label='CPF' />
            <Typography variant='subtitle2'>Formas de pagamento</Typography>
            <Box display='flex'>
              <RHFSwitch name='allowedPayments.money' label='Dinheiro' />
              <RHFSwitch name='allowedPayments.pix' label='PIX' />
              <RHFSwitch name='allowedPayments.credit' label='Crédito' />
              <RHFSwitch name='allowedPayments.debit' label='Débito' />
              <RHFSwitch name='allowedPayments.cashless' label='CashLess' />
            </Box>
            <RHFSelect
              fullWidth
              name='role'
              label='Papel'
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {usersPermissons.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            {!currentUser && (
              <RHFTextField
                name='password'
                label='Senha'
                autoComplete='new-password'
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={password.onToggle} edge='end'>
                        <Iconify
                          icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!currentUser && (
              <RHFTextField
                name='password_confirmation'
                label='Confirmar Senha'
                autoComplete='new-password'
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={passwordConfirm.onToggle} edge='end'>
                        <Iconify
                          icon={passwordConfirm.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {currentUser && (
              <FormControlLabel
                labelPlacement='start'
                control={
                  <Controller
                    name='active'
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(event) => field.onChange(event.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant='subtitle2' sx={{ mb: 0.5 }}>
                      Status
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                      Aplicar desativação de conta
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            {currentUser ? 'Atualizar' : 'Cadastrar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
